import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import { useForm } from "react-hook-form";
import Loader from "./Loaders/Loader";
import Swal from "sweetalert2";
import PostHelper from "../../../utils/PostHelper";



const Notifications = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, downloadLargeData, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true);

    useEffect(() => {document.title = "Notifications | Daniezo"}, []);

    const {register, handleSubmit, formState: {errors, isValid, isSubmitting}} = useForm({
        mode: "onChange"
    });

    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const countPerPage = 30;
    const [searchText, setSearchText] = useState('');
    const [isDivVisible, setDivVisibility] = useState(false);
    const [showSecDownload, setShowSecDownload] = useState(false);


    const columns = [
        {
            name: 'Description',
            selector: row => {
                const customer = row.userDetails?.firstname ? <span>{`${row.userDetails?.firstname} ${row.userDetails?.lastname}`}<span style={{ fontSize:'15px', marginTop:'4px', color:'#069' }}> ({row.userDetails?.phone})</span></span> : <Nulls />;

                const descrip = row.description;
                
                return (
                    <>
                        <b>{customer}</b> {descrip}
                    </>
                )
            },
            sortable: true,
        },
        {
            name: 'Page',
            selector: row => <font style={{ textTransform:'capitalize' }}>{row.page}</font>,
        },
        {
            name: 'Date',
            selector: row => row.created_at ? format(new Date(row.created_at), 'MMM d, yyyy h:mma') : <Nulls />,
            sortable: true,
        },
    ];

    const ExpandableComponent = ({ data }) => {
        const customer = data.userDetails?.firstname ? <span>{`${data.userDetails?.firstname} ${data.userDetails?.lastname}`}</span> : <Nulls />;
        return (
          <div className='text-left th_data'>
            <div><font>Description:</font> <span style={{ textTransform:'none' }}>{customer} {data.description}</span></div>
            <div><font>Date Created:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
          </div>
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            status: status,
            filters: filters,
        };
        setIsFade(true);
        try {
            // const response = await fetch(API_ROUTES.NOTIFICATIONS, {
            //     method: "POST",
            //     body: objectToFormData(dataString),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(API_ROUTES.NOTIFICATIONS, dataString,token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseJSON.data_total;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setDatas([]);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);

    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }

    const fetchDatas = async (apiData) => {
        downloadLargeData(apiData, "transactions", "-transactions-data");
    }

    const handleButtonClick = () => {
        setDivVisibility(!isDivVisible);
        setShowSecDownload(true);
    }
    const handleButtonClose = (e) => {
        e.preventDefault();
        setDivVisibility(!isDivVisible);
        setShowSecDownload(false);
    }



    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--20 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="table-responsive_">

                                        <div className="col-12">
                                            <h2 className="mt-20 mt-xs-30 mb--20 mb-xs-0">Notifications</h2>
                                        </div>

                                        <div className="offset-lg-8 col-lg-4 offset-sm-7 col-sm-5 mb-20 mt-xs-20 mb-xs-20">
                                            <div className="row">
                                                <div className="col-12">
                                                    <input type="text" placeholder="Search by name..."
                                                        className="filter_text"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="mt--15 pb-10" style={{ position:'relative', zIndex:'99' }}>
                                            <form onSubmit={handleSubmit(fetchDatas)}>
                                                <div className={`row sliding-div ${isDivVisible ? 'visible' : ''}`}>
                                                    <div className="col-2 text-right pr-5">
                                                        <button className="close_btns" onClick={(e) => handleButtonClose(e)}>x</button>
                                                    </div>
                                                    <div className="col-5 pl-0 pr-0">
                                                        <input type="date" {...register("dateFrom")} />
                                                    </div>
                                                    <div className="col-5 pl-0">
                                                        <input type="date" {...register("dateTo")} />
                                                    </div>
                                                </div>

                                                {
                                                    !showSecDownload &&
                                                    <button onClick={handleButtonClick} className="showDateBtn"><i className="fa fa-print"></i> <font className="d-none d-sm-inline-block">Download</font> </button>
                                                }
                                                {
                                                    showSecDownload &&
                                                    <button type="submit" 
                                                    disabled={isSubmitting || !isValid} 
                                                    style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}
                                                    className="showDateBtn"
                                                    ><i className="fa fa-print"></i> <font className="d-none d-sm-inline-block">{(isSubmitting && !isValid) ? "Downloading" : "Download"}</font> </button>
                                                }
                                            </form>
                                        </div> */}

                                        {
                                            isLoading ? <Loader /> 
                                            :

                                            <DataTable
                                                className={`mt-20 pt-5 table_css notify_table ${isFade && 'opaqs'}`}
                                                columns={columns}
                                                data={datas}
                                                highlightOnHover
                                                pagination
                                                paginationServer
                                                paginationTotalRows={dataTotal}
                                                paginationPerPage={countPerPage}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true,
                                                }}
                                                onChangePage={(newPage) => setPage(newPage)}
                                                expandableRows
                                                expandableRowsComponent={ExpandableComponent}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notifications;