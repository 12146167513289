import React, { useEffect, useRef, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import {headers} from "../../../server_apis/server";
import Nulls from "../Nulls";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Loader from "./Loaders/Loader";
import PostHelper from "../../../utils/PostHelper";
// import { Document, Page, pdfjs } from 'react-pdf';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.js';
// import pdfjsWorker from 'pdfjs'


const UsersKYC = (props) => {
    // pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true);
    useEffect(() => {document.title = "Users KYC | Daniezo"}, []);

    const [numPages, setNumPages] = useState(null);
    const [showResponse, setShowResponse] = useState(false);
    const [showResponseObj, setShowResponseObj] = useState([]);
    
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const [isLoading, setIsLoading] = useState(true);
    const [showResponseDiv, setShowResponseDiv] = useState(false);
    
    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 30;
    const [searchText, setSearchText] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedFilename, setSelectedFilename] = useState('');
    const [titleName, setTitleName] = useState('');
    const newRandomNumber1 = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
    const newRandomNumber2 = Math.floor(Math.random() * (555 - 100 + 1)) + 100;
    const reason_modal = useRef(null);
    // const [dropdownValues, setDropdownValues] = useState(rows.map(row => row.id_card_status));


    const openModal = (filename, titles) => {
        setSelectedFilename(filename);
        setTitleName(titles);
    }

    
    const showRes = () => {
        setShowResponseDiv(true);
    }


    const [selectedOptions, setSelectedOptions] = useState('');

    const handleSelectChange = async (row, event, columns, row_status) => {
        const newSelectedOptions = [...selectedOptions];
        const dropdown = parseInt(event.target.value);
        newSelectedOptions[row_status] = dropdown;

        // setSelectedOptions(newSelectedOptions);

        let captions = "";
        if(parseInt(dropdown) === 1){
            captions = "Pending";
        }else if(parseInt(dropdown) === 2){
            captions = "Approved";
        }else if(parseInt(dropdown) === 3){
            captions = "Rejected";
        }
        
        if(captions !== ""){
            const result = await Swal.fire({
                title: parseInt(dropdown) !== 3 ? `Change the status to ${captions}` : `Please enter a reason and continue`,
                html: parseInt(dropdown) !== 3 ? 'This process can always be undone, continue?' : 
                `<div class="text-left swal_inputs col-12">
                    <textarea id="reason" class="swal2-textarea" placeholder="Enter a reason for decline"></textarea>
                </div>`,
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: '#337ab7',
                cancelButtonColor: '#777',
                confirmButtonText: `Yes, Proceed`,
                preConfirm: () => {
                    if(parseInt(dropdown) === 3){
                        const reason = Swal.getPopup().querySelector('#reason').value;
                        if (!reason) {
                            Swal.showValidationMessage(`Please enter a reason`);
                            return;
                        }
                        return { reason: reason }
                    }
                  }
            });
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                try {
                    const dataString = { 
                        reason: parseInt(dropdown) === 3 ? result.value.reason : '',
                        id: row.ids,
                        status: dropdown,
                        columns
                    };
                    
                    // const response = await fetch(API_ROUTES.APPROVE_KYCS, {
                    //     method: 'POST',
                    //     body: objectToFormData(dataString),
                    //     headers: {'hashKey': token}
                    // });
                    const response = await PostHelper(API_ROUTES.APPROVE_KYCS, dataString, token)
                    const responseJSON = await response.json();
                    isAuthExpired(responseJSON); // check for expired login
    
                    if (response.ok) {
                        Swal.fire({
                            title: "Successful",
                            html: responseJSON.message,
                            icon: "success",
                            confirmButtonColor: '#393472',
                        }); 
                        return;
                    } else {
                        Swal.fire({
                            title: 'Error',
                            html: responseJSON.message || 'An error occurred during the POST request.',
                            icon: 'error',
                        });
                        // setSelectedValue(row_status);
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        html: "Network Error! Kindly check your network connection",
                        icon: 'error',
                    });
                    // setSelectedValue(row_status);
                }
                return;
            }
            setSelectedValue(row_status);
        }

    };


    const handleVerifyAddress = async (data) => {
        //if(data.res_addr && data.res_state && data.res_city && data.res_lga){
        if(data.res_addr && data.res_state && data.res_city){
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });

            const dataString = { 
                user: data.user_id,
                street: data.res_addr,
                stateName: data.res_state,
                lgaName: data.res_city,
                city: data.res_city,
                medium: "qoreid",
            };

            setIsFade(true);
            // setIsLoading(true);
            try {
                // const response = await fetch(API_ROUTES.VERIFY_CUSTOMER_ADDR, {
                //     method: "POST",
                //     body: objectToFormData(dataString),
                //     headers: {'hashKey': token}
                // });
                const response = await PostHelper(API_ROUTES.VERIFY_CUSTOMER_ADDR, dataString, token)
                const responseJSON = await response.json();
                isAuthExpired(responseJSON); // check for expired login
    
                if (responseJSON.status.toString() === "200") {
                    setShowResponse(true);
                    setShowResponseObj('Please reload to view this');
                    
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: 'success',
                    });
                    setIsFade(false);
                    setIsLoading(false);
                    return;
                }
                Swal.fire({
                    title: "Error!",
                    html: responseJSON.message,
                    icon: 'error',
                });
                setIsFade(false);
                setIsLoading(false);
            } catch (error) {
                console.log('Error getting data: ', error);
                setIsLoading(false);
                setIsFade(false);
                Swal.fire({
                    title: 'Error',
                    html: "Network Error! Kindly check your network connection",
                    icon: 'error',
                });
            }
        }else{
            setIsFade(false);
            Swal.fire({
                title: 'Error',
                html: "No complete address found for verification",
                icon: 'error',
            });
        }
    }


    const handleDropDownStatus = async (row, event, columns, row_status) => {

        /*let captions = "";
        if(parseInt(dropdown) === 1){
            captions = "Pending";
        }else if(parseInt(dropdown) === 2){
            captions = "Approved";
        }else if(parseInt(dropdown) === 3){
            captions = "Rejected";
        }
        
        if(captions !== ""){
            const result = await Swal.fire({
                title: parseInt(dropdown) !== 3 ? `Change the status to ${captions}` : `Please enter a reason and continue`,
                html: parseInt(dropdown) !== 3 ? 'This process can always be undone, continue?' : 
                `<div class="text-left swal_inputs col-12">
                    <textarea id="reason" class="swal2-textarea" placeholder="Enter a reason for decline"></textarea>
                </div>`,
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: '#337ab7',
                cancelButtonColor: '#777',
                confirmButtonText: `Yes, Proceed`,
                preConfirm: () => {
                    if(parseInt(dropdown) === 3){
                        const reason = Swal.getPopup().querySelector('#reason').value;
                        if (!reason) {
                        Swal.showValidationMessage(`Please enter a reason`);
                        return;
                        }
                        return { reason: reason }
                    }
                  }
            });
            // return;
            if (result.isConfirmed) {
                // if (reason_modal.current) {
                //     const modal = new window.bootstrap.Modal(reason_modal.current);
                //     modal.show();
                // }

                // return;
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                try {
                    const dataString = { 
                        reason: parseInt(dropdown) === 3 ? result.value.reason : '',
                        id: row.ids,
                        status: dropdown,
                        columns
                    };
                    
                    const response = await fetch(API_ROUTES.APPROVE_KYCS, {
                        method: 'POST',
                        body: objectToFormData(dataString),
                        headers: {'hashKey': token}
                    });
                    const responseJSON = await response.json();
                    isAuthExpired(responseJSON); // check for expired login
    
                    if (response.ok) {
                        Swal.fire({
                            title: "Successful",
                            html: responseJSON.message,
                            icon: "success",
                            confirmButtonColor: '#393472',
                        }); 
                        return;
                    } else {
                        Swal.fire({
                            title: 'Error',
                            html: responseJSON.message || 'An error occurred during the POST request.',
                            icon: 'error',
                        });
                        setSelectedValue(row_status);
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        html: "Network Error! Kindly check your network connection",
                        icon: 'error',
                    });
                    setSelectedValue(row_status);
                }
                return;
            }
            setSelectedValue(row_status);
        }*/
        
    }


    const columns = [
        {
            name: 'Customer',
            selector: row => 
            row.userDetails 
            ?
            row.userDetails?.firstname !== null
            ?
            <div style={{ textTransform:'none' }}>{`${row.userDetails?.firstname} ${row.userDetails?.lastname}`}<div style={{ fontSize:'15px', marginTop:'4px', color:'#069' }}>({row.userDetails?.phone})</div></div>
            :
            row.userDetails?.email
            :
            <span style={{ color:'red', opacity:0.5, fontSize:'12px' }}>Customer Deleted</span>,

            sortable: true,
        },
        {
            name: "ID Card",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.id_card !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.id_card, "Government ID Card")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Card
                                            </Link>
                                            <div>
                                                {
                                                    row.id_card_status === 2
                                                    ?
                                                    <b style={{ color:'green' }}>Approved</b>
                                                    :
                                                    <select 
                                                        key={row.id} 
                                                        defaultValue={selectedOptions[row.id] ? selectedOptions[row.id] : row.id_card_status} 
                                                        onChange={(event) => handleSelectChange(row, event, "id_card_status", row.id_card_status)}
                                                        className='dropdowns'
                                                    >
                                                        <option value="0">-Select-</option>
                                                        <option value="1">Pending</option>
                                                        <option value="2">Approve</option>
                                                        <option value="3">Reject</option>
                                                    </select>
                                                }
                                                
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: "Selfie",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.liveness_image !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.liveness_image, "Selfie Photo")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Image
                                            </Link>
                                            <div>
                                                {/* <select 
                                                    onChange={(e) => handleSelectChange(row, e, "selfie_status", row.selfie_status)} 
                                                    className='dropdowns' 
                                                    value={selectedValue !== "" ? selectedValue : row.selfie_status} 
                                                    defaultValue={row.selfie_status}
                                                > */}

                                                {
                                                    row.liveness_check_image === 2
                                                    ?
                                                    <b style={{ color:'green' }}>Approved</b>
                                                    :
                                                    <select 
                                                        key={row.id} 
                                                        defaultValue={selectedOptions[row.id] ? selectedOptions[row.id] : row.liveness_check_image} 
                                                        onChange={(event) => handleSelectChange(row, event, "liveness_check_image", row.liveness_check_image)}
                                                        className='dropdowns'
                                                    >
                                                        <option value="0">-Select-</option>
                                                        <option value="1">Pending</option>
                                                        <option value="2">Approve</option>
                                                        <option value="3">Reject</option>
                                                    </select>
                                                }
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: "Utility",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.utility !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.utility, "Utilities")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Image
                                            </Link>
                                            <div>

                                                {
                                                    row.utility_status === 2
                                                    ?
                                                    <b style={{ color:'green' }}>Approved</b>
                                                    :
                                                    <select 
                                                        key={row.id} 
                                                        defaultValue={selectedOptions[row.id] ? selectedOptions[row.id] : row.utility_status} 
                                                        onChange={(event) => handleSelectChange(row, event, "utility_status", row.utility_status)}
                                                        className='dropdowns'
                                                    >
                                                        <option value="0">-Select-</option>
                                                        <option value="1">Pending</option>
                                                        <option value="2">Approve</option>
                                                        <option value="3">Reject</option>
                                                    </select>
                                                }
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: "Proof of Address",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.addr_proof !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.addr_proof, "Proof of Address")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Image
                                            </Link>
                                            <div>
                                                {
                                                    row.addr_proof_status === 2
                                                    ?
                                                    <b style={{ color:'green' }}>Approved</b>
                                                    :
                                                    <select 
                                                        key={row.id} 
                                                        defaultValue={selectedOptions[row.id] ? selectedOptions[row.id] : row.addr_proof_status} 
                                                        onChange={(event) => handleSelectChange(row, event, "addr_proof_status", row.addr_proof_status)}
                                                        className='dropdowns'
                                                    >
                                                        <option value="0">-Select-</option>
                                                        <option value="1">Pending</option>
                                                        <option value="2">Approve</option>
                                                        <option value="3">Reject</option>
                                                    </select>
                                                }
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: "CAC",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.cac !== null ?
                                        <>
                                            <Link className="view_slip" onClick={() => openModal(row.cac, "CAC")} data-toggle="modal" data-target="#view_photo">
                                                <i className='fa fa-download' /> View Image
                                            </Link>
                                            <div>
                                                {
                                                    row.cac_status === 2
                                                    ?
                                                    <b style={{ color:'green' }}>Approved</b>
                                                    :
                                                    <select 
                                                        key={row.id} 
                                                        defaultValue={selectedOptions[row.id] ? selectedOptions[row.id] : row.cac_status} 
                                                        onChange={(event) => handleSelectChange(row, event, "cac_status", row.cac_status)}
                                                        className='dropdowns'
                                                    >
                                                        <option value="0">-Select-</option>
                                                        <option value="1">Pending</option>
                                                        <option value="2">Approve</option>
                                                        <option value="3">Reject</option>
                                                    </select>
                                                }
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
    ];

    const ExpandableComponent = ({ data }) => {
        const adminName = data.admin?.firstname ? `${data.admin?.firstname} ${data.admin?.lastname}` : data.admin?.email;
        return (
          <div className='text-left th_data'>
            <div><font>Approve/Rejected By: <span style={{ color:'#069' }}>{adminName}</span></font></div>
            <div>
                <font>Customer:</font> {` `}
                {data.userDetails 
                ?
                data.userDetails?.firstname !== null
                ?
                <div>{`${data.userDetails?.firstname} ${data.userDetails?.lastname}`}<div style={{ fontSize:'15px', marginTop:'4px', color:'#069' }}>({data.userDetails?.phone})</div></div>
                :
                data.userDetails?.email
                :
                <span style={{ color:'red', opacity:0.5, fontSize:'14px' }}>Customer Deleted</span>}
            </div>
            
            <div>
                <font>Bank Statement: {' '}</font>
                {
                    data.bank_statement &&
                    <Link className="view_slip" onClick={() => openModal(data.bank_statement, "Bank Statement Image")} data-toggle="modal" data-target="#view_photo">
                        <i className='fa fa-download' /> View Image
                    </Link> 
                }

                {
                    data.bank_statement_status === 2
                    ?
                    <b style={{ color:'green' }}>Approved</b>
                    :
                    <select 
                        key={data.id} 
                        defaultValue={selectedOptions[data.id] ? selectedOptions[data.id] : data.bank_statement_status} 
                        onChange={(event) => handleSelectChange(data, event, "bank_statement_status", data.bank_statement_status)}
                        style={{ width:'140px' }}
                        className='dropdowns'
                    >
                        <option value="0">-Select-</option>
                        <option value="1">Pending</option>
                        <option value="2">Approve</option>
                        <option value="3">Reject</option>
                    </select>
                }
            </div>

            <div>
                <font>Customer Address:</font> 
                <div style={{ backgroundColor:'#ccc', padding:'10px', borderRadius:'10px' }}>
                    <div><font>Residential Address:</font> {data.res_addr ? data.res_addr : <Nulls /> }</div>
                    <div><font>State:</font> {data.res_state ? data.res_state : <Nulls />}</div>
                    <div><font>City:</font> {data.res_city ? data.res_city : <Nulls />}</div>
                    <div><font>LGA:</font> {data.res_city ? data.res_city : <Nulls />}</div>
                    <div><font>Address Verification:</font> {data.address_response ? <span style={{ fontWeight:'600', fontSize:'13px' }}>{JSON.parse(data.address_response).summary.address_check}</span> : <Nulls />}</div>

                    <div className="col-6 text-start">
                        <div className="row">
                            {
                                <>
                                    <div className="col-6">
                                        <button
                                        className="custom_btn btn-primary"
                                        onClick={() => handleVerifyAddress(data)}
                                        style={{ opacity: (data.res_addr && data.res_state && data.res_city && data.res_city) ? 1 : 0.4 }}
                                        >Click to verify address</button>
                                    </div>

                                    {
                                        !showResponseObj ? showResponseObj : data.address_response
                                    }
                                </>
                            }
                            
                        </div>
                    </div>

                </div>
            </div>
            <div><font>Business Name:</font> {data.business_name ? capitalizeWords(data.business_name) : <Nulls />}</div>
            <div><font>NIN:</font> {data.userDetails?.nin !== null ? <span style={{ textTransform:'none' }}>{data.userDetails?.nin}</span> : <Nulls />}</div>
            <div><font>Next of Kin Name:</font> {data.next_kin_name ? capitalizeWords(data.next_kin_name) : <Nulls /> }</div>
            <div><font>Next of Kin Phone:</font> {data.next_kin_phone ? data.next_kin_phone : <Nulls /> }</div>
            <div><font>Next of Kin Email:</font> {data.next_kin_email ? data.next_kin_email : <Nulls /> }</div>
            <div><font>Bank Name:</font> {data.bank_name ? capitalizeWords(data.bank_name) : <Nulls /> }</div>
            <div><font>Account Name:</font> {data.acct_name ? data.acct_name : <Nulls /> }</div>
            <div><font>Account Number:</font> {data.acct_no ? data.acct_no : <Nulls /> }</div>
            <div><font>Virtual Account No:</font> {data.userDetails?.virtual_acct ? data.userDetails?.virtual_acct : <Nulls /> }</div>
            <div><font>Industry:</font> {data.industry ? capitalizeWords(data.industry) : <Nulls /> }</div>
            <div><font>Business Type:</font> {data.business_type ? capitalizeWords(data.business_type) : <Nulls /> }</div>
            
            <div>
                <font>Pitch Deck: </font> 
                <a href={`${API_ROUTES.SERVER_ENDPOINT}/assets/file_uploads/${data.pitch_deck}`} target = "_blank">Download File</a>

                {
                    data.pitch_deck_status === 2
                    ?
                    <b style={{ color:'green' }}>Approved</b>
                    :
                    <select 
                        key={data.id} 
                        defaultValue={selectedOptions[data.id] ? selectedOptions[data.id] : data.pitch_deck_status} 
                        onChange={(event) => handleSelectChange(data, event, "pitch_deck_status", data.pitch_deck_status)}
                        style={{ width:'140px', marginLeft:15, }}
                        className='dropdowns'
                    >
                        <option value="0">-Select-</option>
                        <option value="1">Pending</option>
                        <option value="2">Approve</option>
                        <option value="3">Reject</option>
                    </select>
                }
            </div>
            
            <div><font>Residential Address:</font> {data.res_addr ? data.res_addr : <Nulls /> }</div>
            <div><font>Location:</font> {data.res_city ? data.res_city : <Nulls />}, {data.res_state ? data.res_state : <Nulls />} (<b>LGA:</b> {data.res_city ? data.res_city : <Nulls />})</div>
            <div><font>Business Address:</font> {data.bis_addr ? data.bis_addr : <Nulls />}</div>
            <div><font>Business Location:</font> {data.bis_city ? data.bis_city : <Nulls />}, {data.bis_state ? data.bis_state : <Nulls />} (<b>LGA:</b> {data.bis_city ? data.bis_city : <Nulls />})</div>
            <div><font>Permanent Address:</font> {data.perm_addr ? data.perm_addr : <Nulls />}</div>
            <div><font>Permanent Location:</font> {data.perm_city ? data.perm_city : <Nulls />}, {data.perm_state ? data.perm_state : <Nulls />} (<b>LGA:</b> {data.perm_city ? data.perm_city : <Nulls />})</div>

            <div><font>Spouse Full Names:</font> {data.spouse_fullname ? capitalizeWords(data.spouse_fullname) : <Nulls /> }</div>
            <div><font>Spouse Phone:</font> {data.spouse_phone ? capitalizeWords(data.spouse_phone) : <Nulls /> }</div>
            <div><font>Spouse Email:</font> {data.spouse_email ? capitalizeWords(data.spouse_email) : <Nulls /> }</div>
            <div><font>Director 1:</font> {data.director1 ? capitalizeWords(data.director1) : <Nulls /> }</div>
            <div><font>Director 1 Email:</font> {data.director1_mail ? capitalizeWords(data.director1_mail) : <Nulls /> }</div>

            <div><font>Director 2:</font> {data.director2 ? capitalizeWords(data.director2) : <Nulls /> }</div>
            <div><font>Director 2 Email:</font> {data.director2_mail ? capitalizeWords(data.director2_mail) : <Nulls /> }</div>

            <div><font>Director 3:</font> {data.director3 ? capitalizeWords(data.director3) : <Nulls /> }</div>
            <div><font>Director 3 Email:</font> {data.director3_mail ? capitalizeWords(data.director3_mail) : <Nulls /> }</div>

            <div><font>Directors BVN:</font> {data.directors_bvn ? capitalizeWords(data.directors_bvn) : <Nulls /> }</div>
            <div><font>Directors Phone:</font> {data.director_phone ? capitalizeWords(data.director_phone) : <Nulls /> }</div>
            <div><font>Directors Email:</font> {data.director_email ? capitalizeWords(data.director_email) : <Nulls /> }</div>

            <div><font>Date Updated:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
            <div><font>Date Updated:</font> {data.updated_at ? format(new Date(data.updated_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
          </div>
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            status: status,
            filters: filters,
        };
        setIsFade(true);
        try {
            // const response = await fetch(API_ROUTES.USERS_KYC, {
            //     method: "POST",
            //     body: objectToFormData(dataString),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(API_ROUTES.USERS_KYC, dataString, token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseJSON.data_total;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                console.log(responseData);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsFade(false);
            setIsLoading(false);
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
        }
    }
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleStatus = async (event) => {
        const filters = event.target.value;
        getDatas(true, filters);
    }
    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }


    const handleStatus1 = async (row, event) => {
        const dropdown = event.target.value;
        const actn = dropdown === 1 ? "Disable" : "Enable";
        
        setSelectedValue(dropdown);
        const result = await Swal.fire({
            title: `Proceed to ${actn} this user account`,
            text: 'This can always be undone, continue?',
            icon: 'question',
            iconHtml: '?',
            showCancelButton: true,
            confirmButtonColor: '#337ab7',
            cancelButtonColor: '#777',
            confirmButtonText: `Yes, Proceed`,
        });
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            try {
                const dataString = { 
                    id: row.ids,
                    status: dropdown,
                };
                // const response = await fetch(API_ROUTES.USERS_ACTION, {
                //     method: 'POST',
                //     body: objectToFormData(dataString),
                //     headers: {'hashKey': token}
                // });
                const response = await PostHelper(API_ROUTES.USERS_ACTION, dataString, token)
                const responseJSON = await response.json();
                isAuthExpired(responseJSON); // check for expired login
                
                if (response.status === 200) {
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    }); 
                    getDatas();
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                    setSelectedValue(row.status);
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error.message || 'An error occurred during the POST request.',
                    icon: 'error',
                });
                setSelectedValue(row.status);
            }
            return;
        }
        setSelectedValue(row.status);
    }




    


    return (
        <>
            <div className="modal fade" id="view_photo" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header p-15">
                            <h4 className="modal-title" id="exampleModalLabel-2">{titleName}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-20">
                            <div>
                                {
                                    // titleName === "Pitch Deck" ?
                                    // <div>
                                    //     <Document
                                    //         file="filename.pdf"
                                    //         onLoadSuccess={onDocumentLoadSuccess}
                                    //     >
                                    //         <Page pageNumber={pageNumber} />
                                    //     </Document>
                                    //     <p>Page {pageNumber} of {numPages}</p>
                                    // </div>
                                    // :
                                    selectedFilename
                                    ?
                                    <img src={`${API_ROUTES.SERVER_ENDPOINT}/assets/file_uploads/${selectedFilename}`} style={{ width:'100%' }} />
                                    :
                                    <p>No Image Uploaded</p>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="table-responsive_">

                                        <div className="offset-md-8 col-md-4 mb--20 mt-xs-20 mb-xs-20">
                                            <div className="row">
                                                <div className="col-12 pl-5">
                                                    <input type="text" placeholder="Search by name..."
                                                        className="filter_text"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            isLoading ? <Loader /> 
                                        :

                                            <DataTable
                                                title="Users KYC"
                                                className={`mt--10 table_css users_kyc ${isFade && 'opaqs'}`}
                                                columns={columns}
                                                data={datas}
                                                highlightOnHover
                                                pagination
                                                paginationServer
                                                paginationTotalRows={dataTotal}
                                                paginationPerPage={countPerPage}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true,
                                                }}
                                                onChangePage={(newPage) => setPage(newPage)}
                                                expandableRows
                                                expandableRowsComponent={ExpandableComponent}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsersKYC;