import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from '../utils/constants';
import encryptData from "../utils/cryptography";
import PostHelper from "../utils/PostHelper";


export default function Login(props){
    useEffect(() => {document.title = "Login | Daneizo"}, []);


    const { objectToFormData, isAdmins, setIsAdmins } = props;
    const rememberLogin = localStorage.getItem('rememberLoginFs');
    const rememberMe = rememberLogin ? true : false;
    const userInput = localStorage.getItem('userInputFs'); 
    const inputObject = JSON.parse(userInput);
    const isEmail = inputObject ? atob(inputObject.email) : '';
    const isPassword = inputObject ? atob(inputObject.password) : '';
    


    // const getAppInfo = async () => {
    //     try {
    //         const response = await fetch("https://owodee.hordecall.net/api/app-info", {
    //             method: "GET",
    //         });
    //         const responseJSON = await response.json();
    //         if (responseJSON.status === 200) {
    //             const responseData = responseJSON.data;
    //             console.log(responseData);
    //             // setAppInfo(responseData);
    //             return;
    //         }
    //     } catch (error) {
    //         console.log('Error getting data: ', error);
    //         // setAppInfo({});
    //     }
    //   }
    
    //   useEffect(() => {
    //       getAppInfo();
    //   }, []);

    const { register, handleSubmit, formState: {errors, isSubmitting, isValid} } = useForm({
        mode: "onChange",
        defaultValues: {
            email: rememberMe ? isEmail : '',
            password: rememberMe ? isPassword : '',
            remember: rememberMe
        }
    });
    const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: {errors: errors1, isSubmitting: isSubmitting1, isValid: isValid1} } = useForm({
        mode: "onChange"
    });

    const [isChecked, setIsChecked] = useState(rememberMe);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showForms, setShowForms] = useState(false);
    const navigate = useNavigate();


    const showFormReset =() => {
        setShowForms(!showForms);
    }
    const showFormLogin =() => {
        setShowForms(!showForms);
    }


    const userData = localStorage.getItem('userDataDani');
    const userObj = JSON.parse(userData);
    // const userObj = userData;
    const isAuthenticated = { 'isLoggedIn': userObj !== null ? userObj.isLoggedIn : false }


    const setLocalStorageAndNavigate = (responseDetails, data) => {
        const userData = {};
        userData.isLoggedIn = true;
        userData.responseDetails = responseDetails;

        localStorage.setItem('userDataDani', JSON.stringify(userData));
        const userInput = {};
        userInput.email = btoa(data.email);
        userInput.password = btoa(data.password);

        if(isChecked){
            localStorage.setItem('userInputFs', JSON.stringify(userInput))
        }else{
            localStorage.removeItem('userInputFs');
        }
        localStorage.setItem('rememberLoginFs', data.remember);
        
        navigate("/dashboard");
    }


    const loginAuth = async (data) => {
        try {
            const response = await PostHelper(API_ROUTES.SIGN_IN, data)
            const responseJSON = await response.json();
            console.log('response gotten 1')
            console.log(responseJSON)
            const message = responseJSON.message;

            if(responseJSON.status.toString() === "200"){
                console.log('sucess 1')
                const responseDetails = responseJSON.data;

                if(responseDetails.userData.enable2fa === 1){ // is2FA
                    const result = await Swal.fire({
                        title: '2 Factor Authentication! Enter the code sent to your email and continue',
                        html: `<div class="text-left swal_inputs swal_inputs_pass">
                                <input type="number" id="ccode" class="swal2-input" placeholder="Enter code">
                            </div>`,
                        confirmButtonText: 'Continue',
                        icon: 'question',
                        iconHtml: '?',
                        focusConfirm: false,
                        showCancelButton: true,
                        cancelButtonColor: '#999',
                        preConfirm: () => {
                            const ccode = Swal.getPopup().querySelector('#ccode').value
                            if (!ccode) {
                            Swal.showValidationMessage(`Please enter code sent to your email`);
                            return;
                            }
                            return { ccode: ccode }
                        }
                    })
                    if (result.isConfirmed) {
                        try {
                            const dataString = { 
                                code: result.value.ccode,
                                email: data.email,
                                password: data.password,
                            };
                            // const dataToSend = encryptData(objectToFormData(dataString))
                            // const response = await fetch(API_ROUTES.TWO_FA_CHECK, {
                            //     method: 'POST',
                            //     body: {'Request_data': dataToSend},
                            // });
                            // console.log('sending2')
                            const response = await PostHelper(API_ROUTES.TWO_FA_CHECK,dataString)
                            const responseJSON = await response.json();
                            const responseDetails = responseJSON.data.userData;
                            
                            // console.log(responseJSON.data);
                            // console.log(responseJSON.data.userData);

                            if(responseJSON.status.toString() === "200"){
                                console.log('response2')
                                setLocalStorageAndNavigate(responseDetails, data);
                                setIsAdmins(responseJSON.data.roles);
                                return;
                            } else {
                                Swal.fire({
                                    title: 'Error',
                                    html: responseJSON.message || 'An error occurred during the POST request.',
                                    icon: 'error',
                                });
                                return;
                            }
                        } catch (error) {
                            Swal.fire({
                                title: 'Error',
                                text: error.message || 'An error occurred during the POST request.',
                                icon: 'error',
                            });
                            return;
                        }
                    };
                }else{
                    setLocalStorageAndNavigate(responseDetails.userData, data);
                    setIsAdmins(responseJSON.data.roles);
                    localStorage.setItem('admin_roles', responseJSON.data.roles);
                }
            }else{
                Swal.fire({
                    title: "<font className='error_title'>Error</font>",
                    html: `${message}`,
                    icon: "error"
                });
                console.log(message)
            }
        } catch (error) {
            Swal.fire({
                icon:   "error",
                html:   error,
                title:  "Error.",
            });
        }
    }
    

    const submitRequests = async (data) => {
        try {
            // const dataToSend = encryptData(objectToFormData(data))
            // const response = await fetch(API_ROUTES.PASSWORD_REQUESTS, {
            //     method: "POST",
            //     body: {'Request_data': dataToSend},
            // });
            const response = await PostHelper(API_ROUTES.PASSWORD_REQUESTS, data)
            const responseJSON = await response.json();
            if(responseJSON.status.toString() === "200"){
                Swal.fire({
                    title: 'Success',
                    html: responseJSON.message,
                    icon: 'success',
                });
                reset1();
                setTimeout(() => {
                    setShowForms(!showForms);
                }, 2000);
            }else{
                Swal.fire({
                    title: "<font className='error_title'>Error</font>",
                    html: responseJSON.message,
                    icon: "error"
                });
            }
        } catch (error) {
            Swal.fire({
                icon:   "error",
                html:   error,
                title:  "Error",
            });
        }
    }



    return (
        <div className="container-scroller logins">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth pr-xs-0">
                <div className="row w-100">
                <div className="col-lg-4 col-sm-7 mx-auto p-xs-0">
                    <div className="auth-form-light text-left p-4">
                        <div className="brand-logo brand-logo-login mb-50">
                            <img src="../assets/images/daniezo.png" alt="logo" />
                        </div>
                        <h4>Administrator Panel</h4>
                        {
                            !showForms &&
                            <div className="formLogin">
                                <h6 className="font-weight-light">Sign in to continue.</h6>
                                <form className="pt-4" onSubmit={handleSubmit(loginAuth)}>
                                    <div className="form-group">
                                        {errors.email && <label className="errors">{errors.email?.message}</label>}

                                        <input className="form-control form-control-lg" id="exampleInputEmail1" type="email" name="email" required="" placeholder="Enter email or phone" {...register("email", {
                                            required: "Your email or phone is required"
                                        })} />
                                    </div>
                                    <div className="form-group">
                                        {errors.password && <label className="errors">{errors.password?.message}</label>}
                                        <input 
                                            className="form-control" 
                                            type={showPassword ? 'text' : 'password'} 
                                            name="password"
                                            placeholder="Enter password" 
                                            defaultValue={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            {...register("password", {required: "Your password is required"})} />
                                    </div>

                                    <div className="mt-30">
                                        {/* <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">SIGN IN</button> */}
                                        <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: isSubmitting ? '0.5' : 1 }}>{isSubmitting ? 'SIGNING IN...' : 'SIGN IN'}</button>
                                    </div>

                                    <div className="my-2 mb-4 d-flex justify-content-between align-items-center">
                                        <label className="container_chk">
                                            Keep me signed in
                                            <input id="checkbox1" type="checkbox" name="remember" {...register('remember')} checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="text-center mt--10 mb-10">
                                        <a href="#" className="" onClick={showFormReset}>
                                            Request for password reset
                                        </a>
                                    </div>
                                </form>
                            </div>
                        }

                        {
                            showForms &&
                            <div className="formReset">
                                <h6 className="font-weight-light">Enter your email to request for password reset</h6>
                                <form className="pt-4" onSubmit={handleSubmit1(submitRequests)}>
                                    <div className="form-group">
                                        {errors1.email && <label className="errors">{errors1.email?.message}</label>}

                                        <input className="form-control form-control-lg" id="exampleInputEmail1" type="email" name="email" required="" placeholder="Enter email or phone" {...register1("email", {
                                            required: "Your email or phone is required"
                                        })} />
                                    </div>

                                    <div className="mt-30">
                                        <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={isSubmitting1 || !isValid1} style={{ opacity: isSubmitting1 ? '0.5' : 1 }}>{isSubmitting1 ? 'REQUESTING...' : 'REQUEST FOR RESET'}</button>
                                    </div>

                                    <div className="text-center mt-20 mb-20">
                                        <a href="#" className="" onClick={showFormLogin}>
                                            Back to Login
                                        </a>
                                    </div>
                                </form>
                            </div>
                        }


                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}