import React, { useEffect, useState } from "react";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import Charts2 from "./Charts/Charts2";
import MenuTop from "./MenuTop";
import axios from "axios";
import ChartsInvestment from "./Charts/ChartsInvestment";
import TimeAgo from "./TimeAgo";
import Swal from "sweetalert2";



const Index = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, isOffcanvasActive, handleMenuToggle, token, newNotify, getNotifyCounts, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true);

    const [totalUsers, setTotalUsers] = useState(0);
    const [totalReqs, setTotalReqs] = useState(0);
    const [totalDefault, setTotalDefault] = useState(0);
    const [totalInvests, setTotalInvests] = useState(0);
    const [totalRepay, setTotalRepay] = useState(0);
    const [totalTrans, setTotalTrans] = useState(0);
    const [totalIntrPenalty, setTotalIntrPenalty] = useState(0);
    const [lastMonthReqs, setLastMonthReqs] = useState(0);
    const [thisMonthReqs, setThisMonthReqs] = useState(0);

    const [lastMonthReqs1, setLastMonthReqs1] = useState(0);
    const [thisMonthReqs1, setThisMonthReqs1] = useState(0);

    const [invested, setInvested] = useState(0);
    const [yields, setYields] = useState(0);
    const [totalNoti, setTotalNoti] = useState(0);

    // const [notifications, setNotifications] = useState([]);
    // const [investments, setInvestments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {document.title = "Dashboard | Daniezo"}, []);


    const fetchData = () => {
        axios.get(API_ROUTES.GET_STATS, { 
            headers: {'hashKey': token}
         }).then(response => {

            const responseJSON = response.data;
            isAuthExpired(responseJSON); // check for expired login

            const myData = response.data.data;
            setTotalUsers(myData.total_users);
            setTotalReqs(myData.total_loan_disbursed);
            setTotalInvests(myData.total_investments);
            setTotalRepay(myData.total_repayment);
            setTotalDefault(myData.total_defaulted);
            setTotalTrans(myData.total_transactions);
            setTotalNoti(myData.total_notifications);
            setTotalIntrPenalty(myData.total_interests_penalty);
            setLastMonthReqs(myData.last_month_loan > 0 ? myData.last_month_loan : 1); // 0 cant work on this chart
            setThisMonthReqs(myData.this_month_loan > 0 ? myData.this_month_loan : 1);

            setLastMonthReqs1(myData.last_month_loan > 0 ? myData.last_month_loan : 0); // 0 cant work on this chart
            setThisMonthReqs1(myData.this_month_loan > 0 ? myData.this_month_loan : 0);

            setIsLoading(false);

        }).catch(err => {
            setIsLoading(false);
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
        });
    };
    useEffect(() => {
        fetchData();
        getNotifyCounts();
        fetchNotifys();
    }, []);


    const openLink = (page) => {
        navigate(`/${page}/`);
    }


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} notifications={notifications} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} notifications={notifications} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-10">
                            <div className="row all_cards mt-2 mt-xs-30">
                                <div className="col-sm-6 col-md-4 col-xl-3 col-6 pl-xs-10 pr-xs-5 pr-md-10 grid-margin stretch-card">
                                    <div className="card bg-gradient-primary text-white text-left card-shadow-primary">
                                        <div className="card-body" style={{ cursor:'pointer' }} onClick={(e) => openLink('view-users')}>
                                            <i className="far fa-user menu-icon d-none d-lg-inline-block"></i>
                                            <div className="stats">
                                                <h6 className="font-weight-normal">Total Users</h6>
                                                <h2 className="mb-0">{totalUsers.toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-xl-3 col-6 pl-xs-5 pr-xs-10 pl-md-10 pr-md-10 grid-margin stretch-card">
                                    <div className="card bg-gradient-danger text-white text-left card-shadow-danger">
                                        <div className="card-body" style={{ cursor:'pointer' }} onClick={(e) => openLink('loan-request')}>
                                            <i className="mdi mdi-coin menu-icon d-none d-lg-inline-block"></i>
                                            <div className="stats">
                                                <h6 className="font-weight-normal"><span className="d-none d-xl-inline-block">Total</span> Loan Disbursed</h6>
                                                <h2 className="mb-0">&#8358;{parseFloat(totalReqs).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-xl-3 col-6 pr-xs-5 pl-md-10 pr-md-10 mt-xs--20 grid-margin stretch-card">
                                    <div className="card bg-gradient-warning_1 text-white text-left card-shadow-warning">
                                        <div className="card-body" style={{ cursor:'pointer' }} onClick={(e) => openLink('loan-request')}>
                                            <i className="mdi mdi-coin menu-icon d-none d-lg-inline-block"></i>
                                            <div className="stats">
                                                <h6 className="font-weight-normal"><span className="d-none d-xl-inline-block">Total</span> Amount Defauled</h6>
                                                <h2 className="mb-0">&#8358;{parseFloat(totalDefault).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-xl-3 col-6 pl-xs-5 pr-xs-10 pl-md-10 mt-xs--20 grid-margin stretch-card">
                                    <div className="card bg-gradient-danger text-white text-left card-shadow-info">
                                        <div className="card-body" style={{ cursor:'pointer' }} onClick={(e) => openLink('loan-request')}>
                                            <i className="mdi mdi-pig menu-icon d-none d-lg-inline-block"></i>
                                            <div className="stats">
                                                <h6 className="font-weight-normal"><span className="d-none d-xl-inline-block">Total</span> Repayment</h6>
                                                <h2 className="mb-0">{parseFloat(totalRepay).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-sm-6 col-md-4 col-xl-3 col-6 pl-xs-10 pr-xs-5 pr-md-10 mt-xs--20 grid-margin stretch-card">
                                    <div className="card bg-gradient-danger text-white text-left card-shadow-primary">
                                        <div className="card-body" style={{ cursor:'pointer' }} onClick={(e) => openLink('view-users')}>
                                            <i className="mdi mdi-pig menu-icon d-none d-lg-inline-block"></i>
                                            <div className="stats">
                                                <h6 className="font-weight-normal">Total Investments</h6>
                                                <h2 className="mb-0">{totalInvests.toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-sm-6 col-md-4 col-xl-3 col-6 pl-xs-5 pl-md-10 pr-md-10 mt-xs--20 grid-margin stretch-card">
                                    <div className="card bg-gradient-warning_1 text-white text-left card-shadow-danger">
                                        <div className="card-body" style={{ cursor:'pointer' }} onClick={(e) => openLink('transactions')}>
                                            <i className="mdi mdi-coin menu-icon d-none d-lg-inline-block"></i>
                                            <div className="stats">
                                                <h6 className="font-weight-normal"><span className="d-none d-xl-inline-block">Total</span> Transactions</h6>
                                                <h2 className="mb-0">{parseFloat(totalTrans).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-xl-3 col-6 pr-xs-5 pl-md-10 pr-md-10 mt-xs--20 grid-margin stretch-card d-none d-md-none d-lg-block">
                                    <div className="card bg-gradient-danger text-white text-left card-shadow-warning">
                                        <div className="card-body" style={{ cursor:'pointer' }} onClick={(e) => openLink('notifications')}>
                                            <i className="mdi mdi-coin menu-icon d-none d-lg-inline-block"></i>
                                            <div className="stats">
                                                <h6 className="font-weight-normal"><span className="d-none d-xl-inline-block">Total</span> Notifications</h6>
                                                <h2 className="mb-0">{parseFloat(totalNoti).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-sm-6 col-md-3 col-xl-3 col-6 pl-xs-5 pr-xs-10 pl-md-10 mt-xs--20 grid-margin stretch-card">
                                    <div className="card bg-gradient-primary text-white text-left card-shadow-info">
                                        <div className="card-body" style={{ cursor:'pointer' }} onClick={(e) => openLink('investments')}>
                                            <i className="mdi mdi-pig menu-icon d-none d-lg-inline-block"></i>
                                            <div className="stats">
                                                <h6 className="font-weight-normal"><span className="d-none d-xl-inline-block">Total</span> Investments</h6>
                                                <h2 className="mb-0">{totalInvests.toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>


                            <div className="row second_card mt-3 long_cards">
                                <div className="col-xl-5 col-md-6 grid-margin stretch-card">
                                    {
                                        isLoading ? "Loading..." :
                                        <Charts2 lastMonthReqs={lastMonthReqs} thisMonthReqs={thisMonthReqs} lastMonthReqs1={lastMonthReqs1} thisMonthReqs1={thisMonthReqs1} totalIntrPenalty={totalIntrPenalty} />
                                    }
                                </div>
                                
                                
                                <div className="col-xl-6 col-md-6 col-sm-6 grid-margin stretch-card">
                                    <div className="card text-left">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="card-title"><i className="mdi mdi-bell"></i> Notifications</h4>
                                                <h4 style={{ fontSize:'14px' }}>
                                                    <Link to="/notifications/" style={{ color:'#5f6be3' }}>
                                                        <i className="mdi mdi-eye"></i> See All
                                                    </Link>
                                                </h4>
                                            </div>
                                            {
                                                notifications.length !== 0 && notifications.map((notification, index) => {
                                                    const names = notification?.userDetails?.firstname !== null || notification?.userDetails?.firstname !== "" ? notification?.userDetails?.firstname : notification?.userDetails?.email;
                                                    return (
                                                        <div className={`list d-flex align-items-center border-bottom py-2`} key={notification.id}>

                                                        <img className="img-sm rounded-circle" src={(notification?.userDetails?.profile_photo !== null && notification?.userDetails?.profile_photo !== undefined) ? `${API_ROUTES.SERVER_ENDPOINT}/assets/profile_photo/${notification?.userDetails?.profile_photo}` : '../assets/images/avatar.png'} />

                                                        <div className="wrapper w-100 ml-3">
                                                            <p className="mb--5" style={{ fontSize:'16px', lineHeight:'22px' }}><b style={{ color:'#5f6be3' }}>{names}</b> {notification.description}</p>

                                                            <small className="text-muted"><TimeAgo createdAt={notification.created_at} /></small>
                                                        </div>
                                                    </div>
                                                    );
                                                })
                                            }

                                            {
                                                notifications.length === 0 && <div>No notification found yet!</div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                
                                {/* <div className="col-xl-4 col-md-12 col-sm-12 grid-margin stretch-card">
                                    {
                                        isLoading ? "Loading..." :
                                        <ChartsInvestment invested={invested} yields={yields} />
                                    }
                                </div> */}
                            </div>

                            {/* <div className="row grid-margin mt-20 mt-xs-0">
                                <div className="col-lg-6 mb-md-30 mb-xs-30">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Last 5 Transaction history</h4>
                                            <div className="d-flex table-responsive">
                                                <div className="btn-group mr-2">
                                                    <Link to="/transactions" className="btn btn-sm btn-primary">
                                                        <i className="mdi mdi-eye"></i> View All
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="table-responsive mt-2 dashboard_table">
                                                <table className="table mt-3 border-top">
                                                    <thead>
                                                        <tr>
                                                            <th>Customer</th>
                                                            <th>Amount</th>
                                                            <th>Description</th>
                                                            <th>Status</th>
                                                            <th>Product</th>
                                                            <th>Payment Method</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            transactions.length !== 0 && transactions.map((transaction, index) => {
                                                                const names = transaction?.userDetails?.firstname === null || transaction?.userDetails?.firstname === "" ? transaction?.userDetails?.email : (`${transaction?.userDetails?.firstname} ${transaction?.userDetails?.lastname}`)
                                                                return (
                                                                    <tr key={transaction.id}>
                                                                        <td>{names}</td>
                                                                        <td>&#8358;{parseFloat(transaction.amount).toLocaleString()}</td>
                                                                        <td>{transaction.description}</td>
                                                                        <td><div className={`badge ${transaction.status === 1 ? 'badge-success' : 'badge-warning'} badge-fw`}>{transaction.status === 1 ? 'Completed' : 'Pending'}</div></td>
                                                                        <td>{transaction.products}</td>
                                                                        <td>{transaction.payment_method}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            transactions.length === 0 && 
                                                            <tr>
                                                                <td colSpan={3}>
                                                                    <div className="text-center">No transactions found yet</div>
                                                                </td>
                                                            </tr>
                                                        }
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Last 5 Investments</h4>
                                            <div className="d-flex table-responsive">
                                                <div className="btn-group mr-2">
                                                <button className="btn btn-sm btn-primary"><i className="mdi mdi-eye"></i> View All</button>
                                                </div>
                                            </div>
                                            <div className="table-responsive mt-2 dashboard_table">
                                                <table className="table mt-3 border-top">
                                                    <thead>
                                                        <tr>
                                                            <th>Customer</th>
                                                            <th>Amount</th>
                                                            <th>Product</th>
                                                            <th>Status</th>
                                                            <th>Description</th>
                                                            <th>Payment Method</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        

                                                        <tr>
                                                            <td colSpan={4}>
                                                                {
                                                                    investments.length === 0 && <div className="text-center">No investment found yet</div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;