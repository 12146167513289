import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Navigate, Route, Routes, useNavigate, Redirect } from 'react-router-dom';
import PrivateRoutes from "../../utils/PrivateRoutes";
import Login from "../Login";
import Index from "../Dashboards/Index";
import $ from 'jquery';
import Swal from "sweetalert2";
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { useLocation } from 'react-router-dom';
import LoanRequests from "../Dashboards/AllTables/LoanRequests";
import Users from "../Dashboards/AllTables/Users";
import UsersKYC from "../Dashboards/AllTables/UsersKYC";
import Transactions from "../Dashboards/AllTables/Transactions";
import UserWallets from "../Dashboards/AllTables/UserWallets";
import UserCards from "../Dashboards/AllTables/UserCards";
import WalletHistory from "../Dashboards/AllTables/WalletHistory";
import UserBanks from "../Dashboards/AllTables/UserBanks";
import ChangePassword from "../Dashboards/AllTables/Settings/ChangePassword";
import LoanSettings from "../Dashboards/AllTables/Settings/LoanSettings";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { API_ROUTES } from "../../utils/constants";
import Notifications from "../Dashboards/AllTables/Notifications";
import Aggregators from "../Dashboards/AllTables/Aggregators";
import Guarantors from "../Dashboards/AllTables/Guarantors";
import axios from "axios";
import NotFound from "../NotFound/NotFound";
import GeneralSettings from "../Dashboards/AllTables/Settings/GeneralSettings";
import AddAdmin from "../Dashboards/AllTables/AddAdmin";
import Permissions from "../Dashboards/AllTables/Permissions";
import PasswordRequests from "../Dashboards/AllTables/PasswordRequests";
import PostHelper from "../../utils/PostHelper";



export default function RouterComponent(){
    const userDataDani = localStorage.getItem('userDataDani');
    const userData = userDataDani ? JSON.parse(userDataDani) : '';

    const navigate = useNavigate();
    const location = useLocation();
    const [newNotify, setNewNotify] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const token = userData?.responseDetails?.hash || '';

    const objectToFormData = (obj) => {
        const formData = new FormData();      
        Object.entries(obj).forEach(([key, value]) => {
          formData.append(key, value);
        });
        return formData;
    }

    
    const formatDate = (dateString) => {
        const options = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          //day: '2-digit'
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
        return formattedDate;
    };
    
    const capitalizeFirstLetter = (string) => {
        if(typeof string === 'string' && string.trim().length >0){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }else{
            return ""
        }
        
    }
    
    const capitalizeWords = (string) => {
        let words = string;
        if(typeof str === 'string' && string.trim().length>0){
            words = string.split(' ');
            const capitalizedWords = words.map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
              });
              return capitalizedWords.join(' ');
        }else{
            return ''
        }


        
    }

    const errorFunction = (error) => {
        if(error.response.status.toString() === "400" || error.response.status.toString() === "401"){
            Swal.fire({
                title: "Error",
                text: error.response.data.message,
                icon: "error"
            });
        }else{
            Swal.fire({
                title: "Error",
                text: error,
                icon: "error"
            });
        }
    }


    const getNotifyCounts = async () => {
        try {
            const response = await fetch(API_ROUTES.NOTIFYCOUNTS, {
                method: "GET",
                headers: {'hashKey': token}
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const counts = parseInt(responseData) >= 9 ? `${responseData}+` : responseData;
                setNewNotify(counts);
            }
        } catch (error) {
            setNewNotify(0);
        }
    }


    const fetchNotifys = () => {
        axios.get(API_ROUTES.GET_STATS, { 
            headers: {'hashKey': token}
         }).then(response => {
            const myData = response.data.data;
            setNotifications(myData.notifications);

        }).catch(err => {
        });
    }


    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const downloadLargeData = async (apiData, table, fileNamePart) => {
        if(apiData.dateFrom === "" || apiData.dateTo === ""){
            Swal.fire({
                title: "Error!",
                html: "Select <b>'From'</b> and <b>'To'</b> field to download data",
                icon: "error",
                confirmButtonColor: '#393472',
            }); 
            return;
        }
        const dataString = { 
            froms: apiData.dateFrom,
            tos: apiData.dateTo,
            status: apiData.status,
            tables: table,
        };
        try {
            // const response = await fetch(API_ROUTES.LARGE_DATA, {
            //     method: "POST",
            //     body: objectToFormData(dataString),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(API_ROUTES.LARGE_DATA, dataString,token)
            const responseJSON = await response.json();
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;

                responseData.forEach(obj => {
                    if (obj.hasOwnProperty('id')) {
                        // obj.customer_id = obj.user_id;
                        // obj.customer = obj.user_id;
                        // delete obj.user_id; // Optionally, delete the old property
                        delete obj.id;

                        
                    }
                });

                const currentDate = new Date();
                const todays = currentDate.toLocaleDateString().replace(/\//g, '-');

                const fileName = todays + fileNamePart;
                const ws = XLSX.utils.json_to_sheet(responseData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
                return;
            }
            Swal.fire({
                title: "Error!",
                html: responseJSON.message,
                icon: "error",
                confirmButtonColor: '#393472',
            });
            
        } catch (error) {
            console.log('Error getting data: ', error);
        }
    }



    useEffect(() => { 
        if(["/dashboard/", "/loan-request/", "/view-users/", "/user-kyc/", "/transactions/", "/password-resets/", "/user-wallets/", "/wallet-history/", "/user-cards/", "/user-banks/", "/change-password/", "/loan-settings/", "/notifications/", "/aggregators/", "/guarantors/", "/general-settings/"].includes(location.pathname)){
            setTimeout(() => {
                const body = document.querySelector('body');
                const contentWrapper = document.querySelector('.content-wrapper');
                const scroller = document.querySelector('.container-scroller');
                const footer = document.querySelector('.footer');
                const sidebar = document.querySelector('.sidebar');
            
                function addActiveClass(element) {
                    const current = location.pathname.split('/').slice(-1)[0].replace(/^\/|\/$/g, '');
                    //   const current = location.pathname.split('/').slice(-1)[0].replace(/^\/|\/$/g, '');
                    if (current === '') {
                        // for root url
                        if (element.getAttribute('href').indexOf('index.html') !== -1) {
                        element.closest('.nav-item').classList.add('active');
                        if (element.closest('.sub-menu')) {
                            element.closest('.collapse').classList.add('show');
                            element.classList.add('active');
                        }
                        }
                    } else {
                        // for other url
                        if (element.getAttribute('href').indexOf(current) !== -1) {
                        element.closest('.nav-item').classList.add('active');
                        if (element.closest('.sub-menu')) {
                            element.closest('.collapse').classList.add('show');
                            element.classList.add('active');
                        }
                        if (element.closest('.submenu-item')) {
                            element.classList.add('active');
                        }
                        }
                    }
                }
            
                document.querySelectorAll('.nav li a', sidebar).forEach((element) => {
                    addActiveClass(element);
                });
            
                document.querySelectorAll('.horizontal-menu .nav li a').forEach((element) => {
                    addActiveClass(element);
                });
            
                // Close other submenu in sidebar on opening any
                if(sidebar){
                    sidebar.addEventListener('show.bs.collapse', (event) => {
                        const shownElement = event.target;
                        Array.from(sidebar.querySelectorAll('.collapse.show'))
                            .filter((element) => element !== shownElement)
                            .forEach((element) => element.classList.remove('show'));
                    });
                }
            
                // Change sidebar and content-wrapper height
                applyStyles();
            
                function applyStyles() {
                // Applying perfect scrollbar
                if (!body.classList.contains('rtl')) {
                    if (document.querySelector('.settings-panel .tab-content .tab-pane.scroll-wrapper')) {
                    const settingsPanelScroll = new PerfectScrollbar('.settings-panel .tab-content .tab-pane.scroll-wrapper');
                    }
                    if (document.querySelector('.chats')) {
                    const chatsScroll = new PerfectScrollbar('.chats');
                    }
                    if (body.classList.contains('sidebar-fixed')) {
                    const fixedSidebarScroll = new PerfectScrollbar('#sidebar .nav');
                    }
                }
                }
            
                if(document.querySelector('[data-toggle="minimize"]')){
                    document.querySelector('[data-toggle="minimize"]').addEventListener('click', () => {
                    if (body.classList.contains('sidebar-toggle-display') || body.classList.contains('sidebar-absolute')) {
                        body.classList.toggle('sidebar-hidden');
                    } else {
                        body.classList.toggle('sidebar-icon-only');
                    }
                    });
                }
            
                // Checkbox and radios
                document.querySelectorAll('.form-check label, .form-radio label').forEach((label) => {
                label.insertAdjacentHTML('beforeend', '<i class="input-helper"></i>');
                });
            
                // Horizontal menu in mobile
                // document.querySelector('[data-toggle="horizontal-menu-toggle"]').addEventListener('click', () => {
                //     document.querySelector('.horizontal-menu .bottom-navbar').classList.toggle('header-toggled');
                // });
            
                // Horizontal menu navigation in mobile menu on click
                const navItemClicked = document.querySelectorAll('.horizontal-menu .page-navigation > .nav-item');
                navItemClicked.forEach((element) => {
                    element.addEventListener('click', (event) => {
                        if (window.matchMedia('(max-width: 991px)').matches) {
                            if (!element.classList.contains('show-submenu')) {
                                navItemClicked.forEach((item) => item.classList.remove('show-submenu'));
                            }
                            element.classList.toggle('show-submenu');
                        }
                    });
                });
            
                /* Fix the bottom navbar to top on scrolling */
                const bottomNavBar = document.querySelector('.bottom-navbar');
                if (bottomNavBar) {
                const navbarStickyPoint = bottomNavBar.offsetTop;
                window.addEventListener('scroll', () => {
                    if (window.matchMedia('(min-width: 992px)').matches) {
                    const header = document.querySelector('.horizontal-menu');
                    if (window.scrollY > navbarStickyPoint) {
                        header.classList.add('fixed-on-scroll');
                    } else {
                        header.classList.remove('fixed-on-scroll');
                    }
                    }
                });
                }
            }, 500);
        }
    }, [location]);




    useEffect(() => {
        var flapt_window = $(window);
        var pageWrapper = $(".flapt-page-wrapper");
        var sideMenuArea = $(".flapt-sidemenu-wrapper");
        var fullScreen = $("body")[0];

        // :: Preloader Active Code
        flapt_window.on('load', function () {
            $('#preloader').fadeOut('slow', function () {
                $(this).remove();
            });
        });

        // $(function() {
        //     $('[data-toggle="offcanvas"]').on("click", function() {
        //         $('.sidebar-offcanvas').toggleClass('active');
        //         alert('ssss')
        //     });
        // });

    }, []);




    const isAuthExpired = (responseJSON) => {
        if (responseJSON.status.toString() === "002"){
            Swal.fire({
                title: "Error1",
                text: 'User session expired',
                icon: "error"
            });
            navigate("/login");
            
            return true;
        }
    }
   
    const [isOffcanvasActive, setOffcanvasActive] = useState(false);

    const handleMenuToggle = () => {
        setOffcanvasActive((prev) => !prev);
    }


    const admins = localStorage.getItem('admin_roles');
    const [isAdmins, setIsAdmins] = useState(admins);


    return (
        <Routes>
            
            <Route path="/" element={<Login isAdmins={isAdmins} setIsAdmins={setIsAdmins} objectToFormData={objectToFormData} />} />
            <Route path="/login" element={<Login isAdmins={isAdmins} setIsAdmins={setIsAdmins} objectToFormData={objectToFormData} />} />

            <Route path="*" element={<NotFound />} />

            <Route path="/dashboard/" element={
                <PrivateRoutes redirectTo="/login">
                    <Index isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} objectToFormData={objectToFormData} />
                </PrivateRoutes>
            }/>

            {
                (isAdmins && (isAdmins.includes("can view/write loan requests") || isAdmins === "00")) && (
                    <Route path="/loan-request" element={
                        <PrivateRoutes redirectTo="/login">
                            <LoanRequests isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} downloadLargeData={downloadLargeData} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                        </PrivateRoutes>
                    }/>
                )
            }

            <Route path="/view-users" element={
                <PrivateRoutes redirectTo="/login">
                    <Users isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} downloadLargeData={downloadLargeData} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            {
                (isAdmins && (isAdmins.includes("can view users kyc") || isAdmins === "00")) && (
                    <Route path="/user-kyc" element={
                        <PrivateRoutes redirectTo="/login">
                            <UsersKYC isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                        </PrivateRoutes>
                    }/>
                )
            }

            {
                (isAdmins && (isAdmins.includes("can view transactions") || isAdmins === "00")) && (
                    <Route path="/transactions" element={
                        <PrivateRoutes redirectTo="/login">
                            <Transactions isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} downloadLargeData={downloadLargeData} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                        </PrivateRoutes>
                    }/>
                )
            }

            {
                (isAdmins && (isAdmins.includes("can reset users password") || isAdmins === "00")) && (
                    <Route path="/password-resets" element={
                        <PrivateRoutes redirectTo="/login">
                            <PasswordRequests isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} downloadLargeData={downloadLargeData} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                        </PrivateRoutes>
                    }/>
                )
            }

            {
                (isAdmins && (isAdmins.includes("can view user wallets") || isAdmins === "00")) && (
                    <Route path="/user-wallets" element={
                        <PrivateRoutes redirectTo="/login">
                            <UserWallets isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} downloadLargeData={downloadLargeData} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                        </PrivateRoutes>
                    }/>
                )
            }

            <Route path="/wallet-history" element={
                <PrivateRoutes redirectTo="/login">
                    <WalletHistory isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} downloadLargeData={downloadLargeData} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            {
                (isAdmins && (isAdmins.includes("can view user cards") || isAdmins === "00")) && (
                    <Route path="/user-cards" element={
                        <PrivateRoutes redirectTo="/login">
                            <UserCards isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                        </PrivateRoutes>
                    }/>
                )
            }

            <Route path="/user-banks" element={
                <PrivateRoutes redirectTo="/login">
                    <UserBanks isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/change-password" element={
                <PrivateRoutes redirectTo="/login">
                    <ChangePassword isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            {
                (isAdmins && (isAdmins.includes("can edit loan settings") || isAdmins === "00")) && (
                    <Route path="/loan-settings" element={
                        <PrivateRoutes redirectTo="/login">
                            <LoanSettings isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                        </PrivateRoutes>
                    }/>
                )
            }

            <Route path="/notifications" element={
                <PrivateRoutes redirectTo="/login">
                    <Notifications isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>
            
            <Route path="/aggregators" element={
                <PrivateRoutes redirectTo="/login">
                    <Aggregators isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/guarantors" element={
                <PrivateRoutes redirectTo="/login">
                    <Guarantors isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>
            
            <Route path="/general-settings" element={
                <PrivateRoutes redirectTo="/login">
                    <GeneralSettings isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            {
                (isAdmins && (isAdmins.includes("can add admin and roles") || isAdmins === "00")) && (
                    <>
                        <Route path="/edit-admin/:shaId" element={
                            <PrivateRoutes redirectTo="/login">
                                <AddAdmin isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                            </PrivateRoutes>
                        }/>

                        <Route path="/add-admin/" element={
                            <PrivateRoutes redirectTo="/login">
                                <AddAdmin isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                            </PrivateRoutes>
                        }/>
                    </>
                )
            }

            

            <Route path="/roles-permissions" element={
                <PrivateRoutes redirectTo="/login">
                    <Permissions isAdmins={isAdmins} notifications={notifications} fetchNotifys={fetchNotifys} newNotify={newNotify} getNotifyCounts={getNotifyCounts} token={token} Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>
            
        </Routes>
    );
}