// const API_URL = 'http://localhost:3000';

const API_URL = process.env.REACT_APP_API_URL
const SERVER_ENDPOINT = process.env.REACT_APP_SERVER_ENDPOINT
// const SERVER_ENDPOINT = 'http://127.0.0.1:5552';


export const API_ROUTES = {
    SIGN_IN:                `${SERVER_ENDPOINT}/api/admin/login`,
    GET_STATS:              `${SERVER_ENDPOINT}/api/admin/stats`,
    LOAN_REQUESTS:          `${SERVER_ENDPOINT}/api/admin/loan-requests`,
    APPROVE_LOAN:           `${SERVER_ENDPOINT}/api/admin/approve-loan`,
    VIEW_USERS:             `${SERVER_ENDPOINT}/api/admin/users`,
    USERS_ACTION:           `${SERVER_ENDPOINT}/api/admin/user-action`,
    USERS_KYC:              `${SERVER_ENDPOINT}/api/admin/user-kyc`,
    APPROVE_KYCS:           `${SERVER_ENDPOINT}/api/admin/approve-kyc`,
    TRANSACTIONS:           `${SERVER_ENDPOINT}/api/admin/transactions`,
    WALLETS:                `${SERVER_ENDPOINT}/api/admin/wallets`,
    WALLET_HISTORY:         `${SERVER_ENDPOINT}/api/admin/wallet-history`,
    USER_CARDS:             `${SERVER_ENDPOINT}/api/admin/user-cards`,
    USER_BANKS:             `${SERVER_ENDPOINT}/api/admin/user-banks`,
    LOAN_SETTINGS:          `${SERVER_ENDPOINT}/api/admin/loan-settings`,
    SUBMIT_LOAN_SETTINGS:   `${SERVER_ENDPOINT}/api/admin/save-loan-settings`,
    LARGE_DATA:             `${SERVER_ENDPOINT}/api/admin/filter-data`,
    CHANGE_PASSWORD:        `${SERVER_ENDPOINT}/api/admin/change-password`,
    NOTIFICATIONS:          `${SERVER_ENDPOINT}/api/admin/notifications`,
    DELETE_DATA:            `${SERVER_ENDPOINT}/api/admin/delete-data`,
    AGGREGATORS:            `${SERVER_ENDPOINT}/api/admin/aggregators`,
    GUARANTORS:             `${SERVER_ENDPOINT}/api/admin/guarantors`,
    NOTIFYCOUNTS:           `${SERVER_ENDPOINT}/api/admin/new-notification`,
    EXTEND_DURATION:        `${SERVER_ENDPOINT}/api/admin/extend-duration`,
    ENABLE2FA:              `${SERVER_ENDPOINT}/api/admin/check-2fa`,
    PRIVILEGES:             `${SERVER_ENDPOINT}/api/admin/privileges`,
    ADD_ADMIN:              `${SERVER_ENDPOINT}/api/admin/add-admin`,
    EDIT_ADMIN:              `${SERVER_ENDPOINT}/api/admin/edit-admin`,
    VIEW_ADMIN:             `${SERVER_ENDPOINT}/api/admin/view-admin`,
    TWO_FA_CHECK:           `${SERVER_ENDPOINT}/api/admin/two-fa-check`,
    PASSWORD_REQUESTS:      `${SERVER_ENDPOINT}/api/admin/password-requests`,
    VIEW_PASSWORD_REQUESTS: `${SERVER_ENDPOINT}/api/admin/view-password-requests`,
    SEND_NEW_PASSWORD:      `${SERVER_ENDPOINT}/api/admin/send-new-password`,
    GET_STAFF_ID:           `${SERVER_ENDPOINT}/api/admin/get-staff-id`,
    IS_ADMIN_ROLES:         `${SERVER_ENDPOINT}/api/admin/is-admin-roles`,
    APPROVE_GUARANTOR:      `${SERVER_ENDPOINT}/api/admin/approve-guarantor`,
    CREDIT_CHECK:           `${SERVER_ENDPOINT}/api/admin/credit-check`,
    CREATE_INSTALMENTS:     `${SERVER_ENDPOINT}/api/admin/create-instalments`,
    VERIFY_CUSTOMER_ADDR:   `${SERVER_ENDPOINT}/api/admin/address-customer-verify`,
    TOGGLE_WITHDRAWAL:      `${SERVER_ENDPOINT}/api/admin/toggle-withdrawal`,
    

    API_URL,
    SERVER_ENDPOINT,
}