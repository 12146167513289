import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";

export default function Menus(props){
    const { isOffcanvasActive, handleMenuToggle, newNotify, isAdmins } = props;
    const userData = JSON.parse(localStorage.getItem('userDataDani'));

    const token = userData?.responseDetails?.hash || '';

    const navigate = useNavigate();
    const location = useLocation();

    const logout = () => {
        localStorage.removeItem('userDataDani');
        localStorage.removeItem('admin_priv');
        localStorage.removeItem('admin_roles');
        navigate('/login');
    }


    return (
        <nav className={`sidebar sidebar-offcanvas ${isOffcanvasActive ? 'active' : ''} nav_lists`} id="sidebar">
            <div className="all_nav">
                <ul className="nav">
                    <li className={`nav-item ${location.pathname === "/dashboard/" && 'active'}`}>
                        <Link className="nav-link" to="/dashboard/">
                            <i className="mdi mdi-view-dashboard-outline menu-icon"></i>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li>

                    {
                        ((isAdmins && isAdmins.includes("can view/write loan requests")) || isAdmins === "00") && (
                            <li className={`nav-item ${location.pathname === "/loan-request/" && 'active'}`}>
                                <Link className="nav-link" to="/loan-request/">
                                    <i className="mdi mdi-coin menu-icon"></i>
                                    <span className="menu-title">Loan Request</span>
                                </Link>
                            </li>
                        )
                    }

                    <li className={`nav-item ${["/view-users/", "/user-kyc/", "/aggregators/", "/guarantors/"].includes(location.pathname) && 'active'}`}>
                        <a className="nav-link" data-toggle="collapse" href="#users" aria-expanded="false" aria-controls="users">
                        <i className="mdi mdi-account menu-icon"></i>
                        <span className="menu-title">Users</span>
                        <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="users">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/view-users/">View Users</Link></li>
                                {
                                    ((isAdmins && isAdmins.includes("can view users kyc")) || isAdmins === "00") && (
                                        <li className="nav-item"> <Link className="nav-link" to="/user-kyc/">User KYC</Link></li>
                                    )
                                }
                                <li className="nav-item"> <Link className="nav-link" to="/aggregators/">Aggregators</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/guarantors/">Guarantors</Link></li>
                            </ul>
                        </div>
                    </li>

                    <li className={`nav-item ${location.pathname === "/notifications/" && 'active'}`}>
                        <Link className="nav-link" to="/notifications/">
                            <i className="mdi mdi-bell menu-icon"></i>
                            {
                                parseInt(newNotify) > 0 && 
                                <span className="new_notify">{parseInt(newNotify) > 9 ? "9+" : newNotify}</span>
                            }
                            <span className="menu-title">Notifications</span>
                        </Link>
                    </li>

                    {/* {
                        ((isAdmins && isAdmins.includes("can view/write investments")) || isAdmins === "00") && (
                            <li className={`nav-item ${location.pathname === "/investments/" && 'active'}`}>
                                <Link className="nav-link" to="/investments/">
                                    <i className="mdi mdi-pig menu-icon"></i>
                                    <span className="menu-title">Investments</span>
                                </Link>
                            </li>
                        )
                    } */}

                    {
                        ((isAdmins && isAdmins.includes("can view transactions")) || isAdmins === "00") && (
                            <li className={`nav-item ${location.pathname === "/transactions/" && 'active'}`}>
                                <Link className="nav-link" to="/transactions/">
                                    <i className="mdi mdi-history menu-icon"></i>
                                    <span className="menu-title">Transactions</span>
                                </Link>
                            </li>
                        )
                    }

                    {
                        ((isAdmins && isAdmins.includes("can view user wallets")) || isAdmins === "00") && (
                            <li className={`nav-item ${["/user-wallets/", "/wallet-history/"].includes(location.pathname) && 'active'}`}>
                                <a className="nav-link" data-toggle="collapse" href="#wallets" aria-expanded="false" aria-controls="wallets">
                                <i className="mdi mdi-account menu-icon"></i>
                                <span className="menu-title">User Wallets</span>
                                <i className="menu-arrow"></i>
                                </a>
                                <div className="collapse" id="wallets">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item"> <Link className="nav-link" to="/user-wallets/">User Wallet</Link></li>
                                        <li className="nav-item"> <Link className="nav-link" to="/wallet-history/">Wallet History</Link></li>
                                    </ul>
                                </div>
                            </li>
                        )
                    }

                    {
                        ((isAdmins && isAdmins.includes("can view user cards")) || isAdmins === "00") && (
                            <li className={`nav-item ${["/user-cards/", "/user-banks/"].includes(location.pathname) && 'active'}`}>
                                <a className="nav-link" data-toggle="collapse" href="#cards" aria-expanded="false" aria-controls="cards">
                                <i className="mdi mdi-cards menu-icon"></i>
                                <span className="menu-title">User Details</span>
                                <i className="menu-arrow"></i>
                                </a>
                                <div className="collapse" id="cards">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item"> <Link className="nav-link" to="/user-cards/">User Cards</Link></li>
                                        <li className="nav-item"> <Link className="nav-link" to="/user-banks/">User Banks</Link></li>
                                    </ul>
                                </div>
                            </li>
                        )
                    }
                    
                    {
                        ((isAdmins && isAdmins.includes("can reset users password")) || isAdmins === "00") && (
                            <li className={`nav-item ${location.pathname === "/password-resets/" && 'active'}`}>
                                <Link className="nav-link" to="/password-resets/">
                                    <i className="mdi mdi-lock-reset menu-icon"></i>
                                    <span className="menu-title">Pass. Resets</span>
                                </Link>
                            </li>
                        )
                    }

                    {
                        ((isAdmins && isAdmins.includes("can add admin and roles")) || isAdmins === "00") && (
                            <li className={`nav-item ${["/add-admin/", "/roles-permissions/"].includes(location.pathname) && 'active'}`}>
                                <a className="nav-link" data-toggle="collapse" href="#perms" aria-expanded="false" aria-controls="perms">
                                    <i className="mdi mdi-security-account menu-icon"></i>
                                    <span className="menu-title">R/Permissions</span>
                                    <i className="menu-arrow"></i>
                                </a>
                                <div className="collapse" id="perms">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item"> <Link className="nav-link" to="/add-admin/">Add Admin</Link></li>
                                        <li className="nav-item"> <Link className="nav-link" to="/roles-permissions/">Permissions</Link></li>
                                    </ul>
                                </div>
                            </li>
                        )
                    }

                    <li className={`nav-item ${["/change-password/", "/general-settings/", "/loan-settings/", "/investment-settings/"].includes(location.pathname) && 'active'}`}>
                        <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                            <i className="mdi mdi-settings menu-icon"></i>
                            <span className="menu-title">Settings</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/general-settings/">General Settings</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/change-password/">Change Password</Link></li>
                                {
                                    ((isAdmins && isAdmins.includes("can edit loan settings")) || isAdmins === "00") && (
                                        <li className="nav-item"> <Link className="nav-link" to="/loan-settings/">Loan Settings</Link></li>
                                    )
                                }
                                {/* {
                                    ((isAdmins && isAdmins.includes("can edit investment settings")) || isAdmins === "00") && (
                                        <li className="nav-item"> <Link className="nav-link" to="/investment-settings/">Investment Settings</Link></li>
                                    )
                                } */}
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/login" onClick={logout}>
                            <i className="mdi mdi-power menu-icon"></i>
                            <span className="menu-title">Logout</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>

    );
}