import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import MenuTop from "../MenuTop";
import Swal from "sweetalert2";
import { API_ROUTES } from "../../../utils/constants";
import { useForm, useWatch } from "react-hook-form";
import { confirmPasswordValidation, passwordValidation } from "../../../utils/Input";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loaders/Loader";
import { Helmet } from "react-helmet";
import PostHelper from "../../../utils/PostHelper";


const AddAdmin = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const { shaId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {document.title = `${(shaId === "" || shaId === undefined) ? "Add" : "Edit"} Admin | Daniezo`}, []);
    const [adminPrivileges, setAdminPrivileges] = useState([]);
    const [getStaffs, setGetStaffs] = useState({});
    const [isSelected, setIsSelected] = useState(0);
    const [isAllChecked, setIsAllChecked] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [names, setNames] = useState('');
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState(0);

    const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
    const headers = { 'hashKey': token }

    const {control, register, handleSubmit, reset, getValues, setValue, formState: {errors, isValid, isSubmitting}} = useForm({
        mode: "onChange"
    });

    const watchedCheckbox = useWatch({
        control: control,
        name: 'user_roles',
        defaultValue: [],
    });

    useEffect(() => {
        getNotifyCounts();
        fetchNotifys();
    }, []);

    
    const fetchData = () => {
        axios.get(API_ROUTES.PRIVILEGES, { 
            headers: {'hashKey': token}
         }).then(response => {
            const responseJSON = response.data;
            isAuthExpired(responseJSON); // check for expired login

            const responseJSON1 = response.data.data;
            setAdminPrivileges(responseJSON1);
            setIsLoading(false);

        }).catch(err => {
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
            setIsLoading(false);
            setAdminPrivileges([]);
        });
    };
    useEffect(() => {
        fetchData();
    }, []);


    const addAdmins = async (data) => {
        const customData = {
            admin_id : shaId ? shaId : null,
            ...data
        }
        try {
            // const response = await fetch(shaId ? API_ROUTES.EDIT_ADMIN : API_ROUTES.ADD_ADMIN, {
            //     method: "POST",
            //     body: objectToFormData(customData),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(shaId ? API_ROUTES.EDIT_ADMIN : API_ROUTES.ADD_ADMIN, customData, token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check htmlFor expired login

            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });

            if(responseJSON.status.toString() === "200"){
                const textMsg = shaId ? `${data.names} details or role permission were updated!` : `${data.names} has been added as Admin!`;
                Swal.fire({
                    title: "Successful",
                    text: textMsg,
                    icon: "success",
                    timer: 8000
                });
                if(!shaId){
                    setTimeout(() => {
                        reset();
                    }, 500);
                }
            }else{
                if(responseJSON.status.toString() === "500"){
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.required_fields,
                        icon: "error",
                    });
                    return;
                }
                Swal.fire({
                    title: "Error",
                    text: responseJSON.message,
                    icon: "error",
                });
            }
        } catch (error) {
            if(error.response){
                Swal.fire({
                    title: "Error",
                    text: error.response,
                    icon: "error",
                });
            }
        }
    }

    const [selectedRoles, setSelectedRoles] = useState([]);


    const handleCheckboxChange = (roleId) => {
        let updatedSelectedIds;
        if (selectedCheckboxIds.includes(roleId)) {
            updatedSelectedIds = selectedCheckboxIds.filter(checkboxId => checkboxId !== roleId);
        } else {
            updatedSelectedIds = [...selectedCheckboxIds, roleId];
        }
        setSelectedCheckboxIds(updatedSelectedIds);
        setValue('user_roles', updatedSelectedIds);
        setIsAllChecked(updatedSelectedIds.length === adminPrivileges.length);
    }

    const handleCheckAllChange = (e) => {
        const isChecked = e.target.checked;
        setIsAllChecked(isChecked);
        const allIds = isChecked ? adminPrivileges.map(privilege => privilege.id) : [];
        setSelectedCheckboxIds(allIds);
        setValue('user_roles', allIds);
    }


    useEffect(() => {
        setValue('user_roles', selectedCheckboxIds);
    }, [selectedCheckboxIds, setValue]);



    const getStaffID = () => {
        axios.get(`${API_ROUTES.GET_STAFF_ID}/${shaId}`, { 
            headers: {'hashKey': token}
         }).then(response => {
            const responseJSON = response.data;
            isAuthExpired(responseJSON); // check for expired login

            if(responseJSON.status.toString() === "200"){
                const responseJSON1 = response.data.data;
                const fullName = `${responseJSON1.firstname} ${responseJSON1.lastname}`;
                setValue('names', fullName);
                setValue('email', responseJSON1.email);
                const rolesArray = responseJSON1.roles.split(',').map(Number);
                setValue('user_roles', rolesArray);

                setNames(fullName);
                setEmail(responseJSON1.email);
                setRoles(rolesArray.length);

                rolesArray.forEach(roleId => {
                    setSelectedCheckboxIds(prevIds => [...prevIds, roleId]);
                });
            }else{
                navigate('/roles-permissions/');
            }
        }).catch(err => {
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
            // navigate('/roles-permissions/');
        });
    }


    useEffect(() => {
        if(shaId !== undefined){
            getStaffID();
        }
    }, []);


    const areFieldsFilled = [email, names, roles].every(field => field);


    return (
        <>
        <div className="container-scroller">
            <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
            
            <div className="container-fluid page-body-wrapper">
                <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                <div className="main-panel">
                    <div className="content-wrapper p-xs-0">
                        <div className="row grid-margin mt--10 mt-xs-0">
                            <div className="col-sm-12 mb-xs-40">
                                <div className="table-responsive_">

                                    <div className="col-12">
                                        <h2 className="mt-20 mt-xs-30 mb-30 mb-xs-30">{(shaId === "" || shaId === undefined) ? "Add" : "Edit"} Admin</h2>
                                    </div>


                                    <div className="col-lg-12 col-sm-12 p-xs-5">
                                        <div className="card_ forms-sample passwords pl-xs-15 pr-xs-15">
                                            <form className="mt-10" onSubmit={handleSubmit(addAdmins)}>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputUsername1">Enter Name</label>
                                                            {errors.names && <label className="errors">{errors.names?.message}</label>}

                                                            <input className="form-control" name="names" type="text" placeholder="Enter Names"
                                                            style={{ textTransform:'capitalize' }}
                                                            {...register("names", { 
                                                                required: "Enter staff name"
                                                                })}
                                                            />
                                                        </div>

                                                        {
                                                            (shaId === "" || shaId === undefined) &&
                                                            <>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputUsername1">Enter Email</label>
                                                                    {errors.email && <label className="errors">{errors.email?.message}</label>}

                                                                    <input className="form-control" name="email" type="email" placeholder="Enter Staff Email"
                                                                    {...register("email", { 
                                                                        required: "Enter staff email"
                                                                        })}
                                                                    />
                                                                </div>
                                                            
                                                            
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                                    {errors.password && <label className="errors">{errors.password?.message}</label>}
                                                                    <input className="form-control" id="pass2" type="password" name="password" placeholder="Enter Password" 
                                                                    {...register("password", {
                                                                    ...passwordValidation
                                                                    })} />
                                                                </div>
                                                            </>
                                                        }

                                                        <button className="btn btn-primary" type="submit" 
                                                        disabled={
                                                            isSubmitting
                                                            // || !areFieldsFilled
                                                        }
                                                        style={{ opacity: (
                                                            isSubmitting 
                                                            // || !(email && names && roles > 0)
                                                            // || !areFieldsFilled
                                                            ) ? '0.5' : 1 
                                                        }}
                                                        >{(shaId === "" || shaId === undefined) ? "Add" : "Edit"} Admin</button>
                                                    </div>

                                                    <div className="col-md-6 mt-xs-40">
                                                        <div className="card-body_ p-20 p-xs-0">
                                                            <div className="table-responsive dashboard_table prv">
                                                                {errors.user_roles && <label className="errors mt-2">{errors.user_roles?.message}</label>}
                                                                <table className={`table border-top ${errors.user_roles ? '' : 'mt-2'}`}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>ID</th>
                                                                            <th>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="checkAll"
                                                                                    checked={isAllChecked}
                                                                                    onChange={handleCheckAllChange}
                                                                                    style={{ marginLeft: '-5px', marginRight:'10px' }}
                                                                                />
                                                                                Permissions
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    {
                                                                        isLoading ? <Loader /> 
                                                                    :
                                                                        <tbody>
                                                                            {
                                                                                adminPrivileges.length !== 0 && adminPrivileges.map((adminPrivilege, index) => {
                                                                                    return (
                                                                                        <tr key={adminPrivilege.id}>
                                                                                            <td style={{ width:'24px' }}>{index+1}.</td>
                                                                                            <td>
                                                                                                <label htmlFor={`chk${adminPrivilege.id}`}>
                                                                                                    <input
                                                                                                        value={adminPrivilege.id}
                                                                                                        id={`chk${adminPrivilege.id}`}
                                                                                                        type="checkbox"
                                                                                                        {...register('user_roles', {
                                                                                                            required: "At least one role is required",
                                                                                                            minLength: 1,
                                                                                                        })}
                                                                                                        onChange={() => handleCheckboxChange(adminPrivilege.id)}
                                                                                                        checked={selectedCheckboxIds.includes(adminPrivilege.id)}
                                                                                                        aria-label={`Permission for ${adminPrivilege.privilege}`}
                                                                                                    /> &nbsp;{adminPrivilege.privilege}

                                                                                                </label>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                adminPrivileges.length === 0 && 
                                                                                <tr>
                                                                                    <td colSpan={0}>
                                                                                        <div className="text-center">No data found yet</div>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Footers />
                </div>
            </div>
        </div>
        </>
    );
}

export default AddAdmin;