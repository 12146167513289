import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loader from "./Loaders/Loader";
import PostHelper from "../../../utils/PostHelper";


const Permissions = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {document.title = "Admin & Roles | Daniezo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 30;
    const [searchText, setSearchText] = useState('');


    const deleteData = async (id, table, event) => {
        const datas = {
            id,
            table
        }
        try {
            const result = await Swal.fire({
                title: `Are you sure you want to delete this admin?`,
                text: 'This cannot be undone, proceed?',
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: 'red',
                cancelButtonColor: '#777',
                confirmButtonText: `Yes, Proceed`,
            });
            if(result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                // const response = await fetch(API_ROUTES.DELETE_DATA, {
                //     method: "POST",
                //     body: objectToFormData(datas),
                //     headers: {'hashKey': token}
                // });
                const response = await PostHelper(API_ROUTES.DELETE_DATA, datas, token)
                const responseJSON = await response.json();
    
                if(responseJSON.status.toString() === "200"){
                    Swal.fire({
                        title: "Successful",
                        html: `Staff has been deleted`,
                        icon: "success",
                        timer: 2000
                    });
                    getDatas();
                }else{
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.message,
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            if(error.response){
                Swal.fire({
                    title: 'Error',
                    html: "Network Error! Kindly check your network connection",
                    icon: 'error',
                });
            }
        }
    }


    const editData = (id) => {
        navigate(`/edit-admin/${id}/`);
    }


    const columns = [
        {
            name: 'Staff',
            selector: row => row.firstname ? <span style={{ textTransform:'capitalize' }}>{`${row.firstname} ${row.lastname}`}</span> : <Nulls />,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone ? row.phone : <Nulls />,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email ? row.email : <Nulls />,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.created_at ? format(new Date(row.created_at), 'MMM d, yyyy h:mma') : <Nulls />,
        },
        {
            name: 'Action',
            selector: row => {
                let actionBtn = '';
                actionBtn = <div className="row mt-1">
                    <div className="col-12">
                        <button className="btn btn-primary btn-xs mr-10" onClick={(e) => editData(row.ids, e)}><span className="fa fa-edit"></span></button>

                        <button className="btn btn-danger btn-xs" onClick={(e) => deleteData(row.ids, "admins", e)}><span className="fa fa-trash"></span></button>
                    </div>
                </div>
                return actionBtn;
            },
        },
    ];

    const ExpandableComponent = ({ data }) => {
        const staff_roles = data.roles;
        return (
            <div className='text-left th_data'>
                <div style={{ marginBottom:'10px' }}>
                    <div style={{ marginBottom:'5px' }}><b>Staff Permission</b></div>
                    {
                        staff_roles.length !== 0 && staff_roles.map((staff_role, index) => {
                            return (
                                <div style={{ marginBottom:'2px', fontSize:'12px', color:'#069' }} key={staff_role.id}><b>{index+1}. {staff_role.privilege}</b></div>
                            );
                        })
                    }
                </div>
                <div><font>Date Created:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
            </div>
            
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            filters: filters,
        };
        setIsFade(true);
        try {
            // const response = await fetch(API_ROUTES.VIEW_ADMIN, {
            //     method: "POST",
            //     body: objectToFormData(dataString),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper (API_ROUTES.VIEW_ADMIN, dataString,token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseJSON.data_total;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
            setDatas([]);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }

    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="col-12">
                                        <h2 className="mt-20 mt-xs-30 mb--20 mb-xs-0">Admin Permissions</h2>
                                    </div>

                                    <div className="offset-lg-8 col-lg-4 offset-sm-7 col-sm-5 mb-20 mt-xs-20 mb-xs-20">
                                        <div className="row">
                                            <div className="col-12">
                                                <input type="text" placeholder="Search by name..."
                                                    className="filter_text"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        isLoading ? <Loader /> 
                                    :

                                        <DataTable
                                            className={`mt-20 table_css ${isFade && 'opaqs'}`}
                                            columns={columns}
                                            data={datas}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={dataTotal}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(newPage) => setPage(newPage)}
                                            expandableRows
                                            expandableRowsComponent={ExpandableComponent}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Permissions;